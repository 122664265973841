<template>
  <section class="about-us py-4 py-sm-6">
    <div class="bs-container">
      <v-card class="bs-shadow-none">
        <div class="pa-6 pa-md-12">
          <AboutUs />
        </div>
      </v-card>
    </div>
  </section>
</template>

<script>
import AboutUs from "@/components/AboutUs.vue";
export default {
  components: {
    AboutUs,
  },
};
</script>
